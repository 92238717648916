<template>
  <div id="manage" class="manage-wrap uf" style="min-width: 1330px">
    <manage-sidebar></manage-sidebar>
    <manage-content></manage-content>
  </div>
</template>

<script>
  import ManageSidebar from './manage-sidebar'
  import ManageContent from './manage-content'
  export default {
    name: 'manage',
    components: {ManageContent, ManageSidebar}
  }
</script>

<style scoped>
  /*.el-aside,*/
  /*.el-main {*/
  /*  height: calc(100vh - 170px);*/
  /*  overflow-y: hidden;*/
  /*}*/

  /*.el-aside >>> .el-scrollbar {*/
  /*  height: calc(100vh - 170px - 50px);*/
  /*}*/

  /*.el-aside .aside-wrap {*/
  /*  height: 100%;*/
  /*  overflow-y: auto;*/
  /*  overflow-x: hidden;*/
  /*  padding-left: 80px;*/
  /*}*/
  .manage-wrap {
    padding-top: 80px;
  }
  .manage-wrap >>> .el-scrollbar__wrap {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }

  .manage-wrap >>> .el-menu {
    border: none;
  }

  .manage-wrap >>> .el-aside .el-scrollbar__view {
    border: 1px solid #e6e6e6;
  }
  .manage-wrap >>> .el-main .el-scrollbar__view {
    height: 100%;
  }
  .manage-wrap >>> .el-scrollbar__bar.is-vertical {
    width: 0;
  }

  .manage-wrap >>> .el-scrollbar__bar.is-horizontal {
    height: 0;
  }

  /*.white-box {*/
  /*  background: #ffffff;*/
  /*  font-size: 14px;*/
  /*}*/
</style>
