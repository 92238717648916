/**
 * 管理中心菜单
 *
 *    key-菜单键值
 *    title-菜单标题
 *    index-效果通菜单键值
 *    icon-菜单icon
 *    hasSub-是否有子菜单
 *    subMenu-子菜单，结构同
 */
export const menu = [
  {
    key: 'home',
    title: '首页',
    index: 'home',
    icon: 'el-icon-s-home',
    hasSub: false,
    subMenu: []
  },
  {
    key: 'enterprise',
    title: '企业信息',
    index: 'enterprise',
    icon: 'el-icon-office-building',
    hasSub: false,
    subMenu: []
  },
  {
    key: 'need',
    title: '我的需求',
    index: 'need',
    icon: 'el-icon-folder',
    hasSub: false,
    subMenu: []
  },
  {
    key: 'solution',
    title: '需求方案',
    index: 'solution',
    icon: 'el-icon-reading',
    hasSub: true,
    subMenu: [
      {
        key: 'solution-pending',
        title: '待确认方案',
        index: 'solution-pending',
        icon: 'el-icon-reading',
        hasSub: false,
        subMenu: []
      },
      {
        key: 'solution-completed',
        title: '已完成方案',
        index: 'solution-completed',
        icon: 'el-icon-reading',
        hasSub: false,
        subMenu: []
      }
    ]
  },
  {
    key: 'tech-component',
    title: '技术构件',
    index: 'tech-component',
    icon: 'el-icon-connection',
    hasSub: true,
    subMenu: [
      {
        key: 'word2vec',
        title: '产品质量安全监测',
        index: 'word2vec',
        icon: 'el-icon-finished',
        hasSub: false,
        subMenu: []
      },
      {
        key: 'brandValue',
        title: '品牌价值在线测算',
        index: 'brandValue',
        icon: 'el-icon-stopwatch',
        hasSub: false,
        subMenu: []
      },
      {
        key: 'credit',
        title: '信用等级在线评价',
        index: 'credit',
        icon: 'el-icon-coordinate',
        hasSub: false,
        subMenu: []
      }
    ]
  },
  {
    key: 'tech-analysis',
    title: '统计分析',
    index: 'tech-analysis',
    icon: 'el-icon-s-home',
    hasSub: false,
    subMenu: []
  },
  {
    key: 'my-order',
    title: '我的订单',
    index: 'my-order',
    icon: 'el-icon-tickets',
    hasSub: false,
    subMenu: []
  },
  // {
  //   key: 'pay-records',
  //   title: '支付记录',
  //   index: 'payRecords',
  //   icon: 'el-icon-mobile',
  //   hasSub: false,
  //   subMenu: []
  // },
  {
    key: 'collect',
    title: '我的收藏',
    index: 'collect',
    icon: 'el-icon-collection',
    hasSub: false,
    subMenu: []
  },
  {
    key: 'view',
    title: '我的足迹',
    index: 'view',
    icon: 'el-icon-position',
    hasSub: false,
    subMenu: []
  },
  //  发票管理
  {
    key: 'invoice',
    title: '发票管理',
    index: 'invoice',
    icon: 'el-icon-document',
    hasSub: true,
    subMenu: [
      {
        key: 'invoice-address',
        title: '收货地址',
        index: 'invoice-address',
        icon: 'el-icon-add-location',
        hasSub: false,
        subMenu: []
      },
      {
        key: 'invoice-rise',
        title: '抬头管理',
        index: 'invoice-rise',
        icon: 'el-icon-set-up',
        hasSub: false,
        subMenu: []
      },
      {
        key: 'invoice-query',
        title: '发票查询',
        index: 'invoice-query',
        icon: 'el-icon-search',
        hasSub: false,
        subMenu: []
      }
      // {
      //   key: 'invoice-qualifications',
      //   title: '增票资质',
      //   index: 'invoice-qualifications',
      //   icon: 'el-icon-edit-outline',
      //   hasSub: false,
      //   subMenu: []
      // }
    ]
  },
  {
    key: 'remind',
    title: '消息中心',
    index: 'remind',
    icon: 'el-icon-chat-line-round',
    hasSub: false,
    subMenu: []
  },
  {
    key: 'account',
    title: '账号管理',
    index: 'account',
    icon: 'el-icon-setting',
    hasSub: false,
    subMenu: []
  },
  // {
  //   key: 'consult',
  //   title: '我的咨询',
  //   index: 'consult',
  //   icon: 'el-icon-message',
  //   hasSub: false,
  //   subMenu: []
  // }
  // consult
];
