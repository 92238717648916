<template>
  <el-aside width="270px">
    <div style="position: relative;height: 100%;padding-left: 60px;overflow: hidden">
      <div class="aside-tit">个人中心</div>
      <el-scrollbar style="position: absolute;top: 50px;width: 100%;">
        <el-menu unique-opened :default-active="currentPage" background-color="#545c64" text-color="#fff" active-text-color="#dcc2ff">
          <manage-sub-menu
              v-for="menu in menuList"
              :key="menu.index"
              :menu="menu">
          </manage-sub-menu>
        </el-menu>
      </el-scrollbar>
    </div>
  </el-aside>
</template>

<script>
import {menu} from '../../router/menu'
import ManageSubMenu from './manage-sub-menu'

export default {
  name: 'manage-sidebar',
  components: {ManageSubMenu},
  data () {
    return {
      menuList: []
    }
  },
  computed: {
    currentPage: {
      get () { return this.$store.state.common.currentPage }
    }
  },
  created () {
    this.menuList = this.$deepClone(menu)
  },
  methods: {

  }
}
</script>

<style scoped>
.el-aside {
  height: calc(100vh - 170px);
  overflow-y: hidden;
}
.el-aside >>> .el-scrollbar {
  height: calc(100vh - 170px - 50px);
}

.el-aside >>> .aside-wrap {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 80px;
}
.el-aside >>> .el-scrollbar__view {
  border: 1px solid #e6e6e6;
}
.aside-tit {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: #04cb94;
  text-align: center;
  padding: 14px;
}
.manage-wrap >>> .el-scrollbar__wrap {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

.manage-wrap >>> .el-menu {
  border: none;
}

.manage-wrap >>> .el-aside .el-scrollbar__view {
  border: 1px solid #e6e6e6;
}
.manage-wrap >>> .el-main .el-scrollbar__view {
  height: 100%;
}
.manage-wrap >>> .el-scrollbar__bar.is-vertical {
  width: 0;
}

.manage-wrap >>> .el-scrollbar__bar.is-horizontal {
  height: 0;
}
</style>
